import { List, Map } from 'immutable'
import _every from 'lodash/every'
import _filter from 'lodash/filter'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _size from 'lodash/size'
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
import { all as allPromise } from 'bluebird'
import { get as getConfig } from 'config'
import {
  setPaymentMethodsProcessing,
  setPaymentMethods,
  setPaymentDataProcessing,
  setPaymentData,
  setPaymentError,
  resetPaymentError,
  setPaymentAuthorization,
} from 'services/payment/actions'
import {
  PAYMENT_ERROR_TYPE_UNKNOWN,
  PAYMENT_ERROR_GIFT_PAYMENT_PENDING,
} from 'services/payment'
import {
  checkVelocity,
  setAuthorization,
  getVelocityCheckActionLocation,
} from 'services/velocity-check'
import { getUserAccountValidation } from 'services/user-account'
import {
  SET_VELOCITY_CHECK_PROCESSING,
  SET_VELOCITY_CHECK_DATA,
  SET_VELOCITY_AUTHORIZATION,
} from 'services/velocity-check/actions'
import { US, CA } from 'services/country'
import { USD } from 'services/currency'
import { getPrimary } from 'services/languages'
import { addNewRelicAction } from 'services/app/_newrelic'
import { PLAN_ID_ONE_WEEK_FREE_TRIAL_TO_MONTHLY } from 'services/plans' // adyen-test-page
import {
  isTestAdyenPage,
  getBaseNewRelicData,
  adyenMerchantAccount,
  getAdyenPaymentMethods,
  allowedPaymentMethods,
  getAdyenAuthorization,
  updateAdyenAuthorization,
  getAdyenPayments,
  getAdyenPaymentDetails,
  handleGetAdyenPayments,
  handleGetAdyenPaymentMethods,
  getPaypalOrder,
  formatBillingAddress,
  getGooglePayScript,
  adyenGooglepayActive,
  ADYEN_PAYMENT_RESPONSE_CODE_AUTHORIZED,
  ADYEN_PAYMENT_RESPONSE_CODE_PENDING,
  ADYEN_PAYMENT_RESPONSE_CODE_RECEIVED,
  ADYEN_PAYMENT_TYPE_CARD,
  ADYEN_PAYMENT_TYPE_PAYPAL,
  ADYEN_PAYMENT_TYPE_APPLEPAY,
  ADYEN_PAYMENT_TYPE_GOOGLEPAY,
  ADYEN_FIELD_DEFAULT_VALUE,
  ADYEN_DEFAULT_US_POSTAL_CODE,
  ADYEN_DEFAULT_CANADA_POSTAL_CODE,
} from '.'
import * as actions from './actions'

const config = getConfig()
const fingerprintJsApiKey = _get(config, 'fingerPrintJsApiKey')
const fingerprintJsSubdomain = _get(config, 'fingerPrintJsSubdomain')
const velocityCheckActive = !!_get(config, ['features', 'checkout', 'velocityCheck'])
const newRelicAdyenPaymentMonitorName = 'AdyenPayment'
const newRelicAdyenPaymentIframeMonitorName = 'AdyenPaymentIframeError'
const giftingPendingRefusalReason = 'Gift payment pending'

/**
 * Get the adyen payment methods
 * @param {Object} options The options
 * @returns The adyen payment methods
 */
async function getPaymentMethods (options = {}) {
  const {
    auth,
    userUuid,
    country,
    primaryLang,
    currencyIso,
  } = options

  const paymentMethods = await getAdyenPaymentMethods({
    auth,
    shopperReference: userUuid,
    merchantAccount: adyenMerchantAccount,
    countryCode: country,
    shopperLocale: primaryLang,
    allowedPaymentMethods,
    amount: { currency: currencyIso, value: 0 },
  })

  return paymentMethods
}

/**
 * Get the fingerprint js results
 * @param {Object} options The options
 * @param {String} options.fingerprintjsProVersion The fingerprintjs pro version for the
 * loader e.g. v3.8.3
 * @returns The fingerprint js results
 */
async function getFingerprint (options = {}) {
  const { fingerprintjsProVersion } = options
  if (!fingerprintjsProVersion) {
    throw new Error('The fingerprintjsProVersion option is required')
  }
  // Load fingerprint js, it will retry internally
  const fp = await FingerprintJS.load({
    apiKey: fingerprintJsApiKey,
    endpoint: fingerprintJsSubdomain,
    scriptUrlPattern: [
      // This endpoint will be used primarily
      `/fingerprintjs/v3/${fingerprintJsApiKey}/loader_v${fingerprintjsProVersion}.js`,
      // The default endpoint will be used if the primary fails
      FingerprintJS.defaultScriptUrlPattern,
    ],
  })
  const fpResult = await fp.get({
    timeout: 10000,
  })
  return fpResult
}

/**
 * Get the fingerprint js valid response and dispatch status
 * @param {Object} options The options
 * @param {Object} options.state The redux state
 * @param {import('redux').Dispatch} options.dispatch Redux action dispatch function
 */
async function getFingerprintProtected (options = {}) {
  const {
    state,
    dispatch,
  } = options
  const {
    auth = Map(),
    user = Map(),
    checkout = Map(),
    resolver = Map(),
    app = Map(),
  } = state
  if (!state) {
    throw new Error('The state option is required')
  }
  if (!dispatch) {
    throw new Error('The dispatch option is required')
  }
  try {
    dispatch({
      type: SET_VELOCITY_CHECK_PROCESSING,
      payload: true,
    })
    const userInfoUuid = user.getIn(['data', 'uuid'])
    let velocityPage = getVelocityCheckActionLocation({ auth, checkout, resolver })
    // adyen-test-page
    if (isTestAdyenPage({ state })) {
      velocityPage = 'checkout'
    }
    const fpResult = await getFingerprint({
      fingerprintjsProVersion: app.get('fingerprintjsProVersion'),
    })
    const velocity = await checkVelocity({
      userInfoUuid,
      fingerprintId: _get(fpResult, 'visitorId'),
      requestId: _get(fpResult, 'requestId'),
      page: velocityPage,
      auth,
    })
    dispatch({
      type: SET_VELOCITY_CHECK_DATA,
      payload: { data: velocity, processing: false },
    })
    return {
      success: true,
      valid: Boolean(_get(velocity, 'success')),
      type: 'user-check',
    }
  } catch (e) {
    dispatch({
      type: SET_VELOCITY_CHECK_DATA,
      payload: { data: { success: false }, processing: false },
    })
    return {
      success: false,
      valid: false,
      type: 'user-check',
    }
  }
}

function setPaymentTokenFailed (options = {}) {
  const {
    auth,
    dropin,
    dispatch,
    refusalReason,
    initialResultCode,
    paymentType,
    authorizationUuid,
    giftPaymentPending,
    velocityCheckToken,
    baseNewRelicData,
  } = options
  const authTokenNewRelicFailureData = {
    paymentType,
    paymentTokenRefusalReason: refusalReason,
    paymentTokenResultCode: initialResultCode,
    paymentTokenSuccess: false,
  }

  // set discarded since there was an error so the worker can clean up the table properly
  updateAdyenAuthorization({ uuid: authorizationUuid, auth, discarded: true })
  dispatch(setPaymentData(handleGetAdyenPayments({}, true)))

  if (giftPaymentPending) {
    dispatch(setPaymentError(PAYMENT_ERROR_GIFT_PAYMENT_PENDING))
  }

  if (velocityCheckActive && velocityCheckToken) {
    dispatch({
      type: SET_VELOCITY_AUTHORIZATION,
      payload: { success: false, reason: refusalReason },
    })
  }

  // send failure data to New Relic
  addNewRelicAction(
    newRelicAdyenPaymentMonitorName,
    { ...baseNewRelicData, ...authTokenNewRelicFailureData },
  )

  // reset the iframe status
  dropin.setStatus('ready')
}

/**
 * Watcher for initializing the Adyen Drop In.
 * @param {Object} param0 The parameters
 * @param {Function} param0.after The witness after function
 */
export function watchInitAdyen ({ after }) {
  return after(actions.INIT_ADYEN, async ({ state, dispatch }) => {
    const {
      user,
      auth,
      plans,
      gift,
      userAccount,
    } = state
    const userRequestCountry = user.getIn(['data', 'requestCountry'])
    const plansCurrency = plans.getIn(['selection', 'currencyIso'])
    const authCountry = auth.get('country')
    const userAccountCountry = userAccount.getIn(['details', 'data', 'billing', 'subscriptions', 'countryIso'])
    const userAccountCurrency = userAccount.getIn(['details', 'data', 'billing', 'subscriptions', 'currencyIso'])
    const giftPlanCurrency = gift.getIn(['details', 'planCurrency'])
    const country = userAccountCountry || authCountry || userRequestCountry || US
    const currencyIso = userAccountCurrency || plansCurrency || giftPlanCurrency || USD
    const primaryLang = getPrimary(user.getIn(['data', 'language'], List()))
    const userUuid = user.getIn(['data', 'uuid'])

    dispatch(setPaymentMethodsProcessing(true))
    dispatch(actions.setAdyenProcessing(true))
    try {
      // Execute work in parallel
      const results = await allPromise([
        getFingerprintProtected({ state, dispatch }),
        (async () => {
          // get google pay script
          if (!adyenGooglepayActive) {
            return {
              valid: true,
              success: true,
              type: 'payment-resources',
            }
          }
          try {
            await getGooglePayScript()
            return {
              valid: true,
              success: true,
              type: 'payment-resources',
            }
          } catch (_) {
            return {
              valid: true,
              success: false,
              type: 'payment-resources',
            }
          }
        })(),
        (async () => {
          const adyenPaymentMethods = await getPaymentMethods({
            auth,
            userUuid,
            country,
            primaryLang,
            currencyIso,
          })
          return {
            valid: true,
            success: true,
            type: 'payment-methods',
            adyenPaymentMethods,
          }
        })(),
      ])
      const { adyenPaymentMethods } = results[2]
      // We did not load any payment method the other checks don't matter
      if (_isEmpty(adyenPaymentMethods)) {
        dispatch(setPaymentMethods(handleGetAdyenPaymentMethods({}, true)))
      } else {
        // Only get validation from user check requests
        const userCheckResults = _filter(results, (result) => _get(result, 'type') === 'user-check')
        // Exclude any checks that were not successfuly from validation
        const userValid = _size(userCheckResults) > 0 && _every(userCheckResults, (result) => _get(result, 'valid'))
        if (userValid) {
          dispatch(setPaymentMethods(adyenPaymentMethods))
        } else {
          dispatch(setPaymentMethods(handleGetAdyenPaymentMethods({}, true)))
        }
      }
      dispatch(actions.setAdyenProcessing(false))
    } catch (_) {
      dispatch(actions.setAdyenProcessing(false))
    }
  })
}

/**
 * Watcher for getting payment authorization from Adyen.
 *
 * @param {Object} param0 The parameters
 * @param {Function} param0.after The witness after function
 */
export function watchGetAdyenPaymentAuthorization ({ after }) {
  return after(
    actions.GET_ADYEN_PAYMENT_AUTHORIZATION,
    async ({ state, action, dispatch }) => {
      const {
        user,
        auth,
        plans,
        payment,
        userAccount,
        velocityCheck,
        checkout,
        resolver,
        app,
        gift,
      } = state
      const { payload } = action
      const {
        billingAddress,
        paymentMethod,
        isGift,
        dropin,
      } = payload
      const shopperReference = user.getIn(['data', 'uuid'])
      const velocityCheckToken = velocityCheck.getIn(['data', 'velocityCheck', 'token'])
      const currencyIso = userAccount.getIn(['details', 'data', 'billing', 'subscriptions', 'currencyIso'])
        || plans.getIn(['selection', 'currencyIso'])
        || gift.getIn(['details', 'planCurrency'])
        || USD
      const paymentType = _get(paymentMethod, 'type')
      const cardPaymentType = paymentType === ADYEN_PAYMENT_TYPE_CARD
      const applepayPaymentType = paymentType === ADYEN_PAYMENT_TYPE_APPLEPAY
      const googlepayPaymentType = paymentType === ADYEN_PAYMENT_TYPE_GOOGLEPAY
      const baseNewRelicData = getBaseNewRelicData(state)
      const testResultCode = payment.getIn(['data', 'testResultCode'])
      const productRatePlanId = isTestAdyenPage({ state }) ? PLAN_ID_ONE_WEEK_FREE_TRIAL_TO_MONTHLY : plans.getIn(['selection', 'id'])
      const billingAccountId = userAccount.getIn(['details', 'data', 'billing', 'subscriptions', 'billingAccountId'])
      let velocityPage = getVelocityCheckActionLocation({ auth, checkout, resolver })

      // kick off the payment process
      dispatch(setPaymentDataProcessing(true))
      dispatch(resetPaymentError())

      // if the payment type is card and
      // if the user doesn't have a Gaia billing account,
      // check the postal code
      if (cardPaymentType && !isGift && !billingAccountId) {
        // validate the postal code
        const accountValidation = await getUserAccountValidation({
          country: _get(billingAddress, 'country'),
          postalCode: _get(billingAddress, 'postalCode'),
          productRatePlanId,
          auth,
        })
        const accountValidationSuccess = _get(accountValidation, 'success')
        const accountValidationErrorCode = _get(accountValidation, ['data', 'errorCode']) || PAYMENT_ERROR_TYPE_UNKNOWN

        // if the postal code is not valid,
        // we will bail and not continue with submitting the payment
        if (!accountValidationSuccess) {
          if (velocityCheckActive) {
            try {
              const userInfoUuid = user.getIn(['data', 'uuid'])

              // adyen-test-page
              if (isTestAdyenPage({ state })) {
                velocityPage = 'checkout'
              }

              // since the postal code failed, call the velocity check again
              dispatch({
                type: SET_VELOCITY_CHECK_PROCESSING,
                payload: true,
              })
              const fpResult = await getFingerprint({
                fingerprintjsProVersion: app.get('fingerprintjsProVersion'),
              })
              const velocity = await checkVelocity({
                userInfoUuid,
                fingerprintId: _get(fpResult, 'visitorId'),
                requestId: _get(fpResult, 'requestId'),
                page: velocityPage,
                auth,
              })
              dispatch({
                type: SET_VELOCITY_CHECK_DATA,
                payload: { data: velocity, processing: false },
              })
            } catch (_) {
              // Velocity check is allowed to fail and check keep processing
              dispatch({
                type: SET_VELOCITY_CHECK_DATA,
                payload: { data: { success: false }, processing: false },
              })
            }
          }

          dispatch(setPaymentError(accountValidationErrorCode))

          // reset the iframe status
          dropin.setStatus('ready')

          return
        }
      }

      // get authorization, which provides the reference/order number
      const authorization = await getAdyenAuthorization({
        auth,
        shopperReference,
        velocityCheckUuid: velocityCheckToken,
        pageName: velocityPage,
      })
      const reference = _get(authorization, 'reference')
      const authorizationUuid = _get(authorization, 'uuid')

      if (_get(authorization, 'success') && reference) {
        // for non-card payments, set the authorization
        if (!cardPaymentType) {
          dispatch(setPaymentAuthorization(authorization))
        }

        // get payments data
        const payments = await getAdyenPayments({
          auth,
          shopperReference: user.getIn(['data', 'uuid']),
          shopperIP: user.getIn(['data', 'xff']),
          shopperEmail: user.getIn(['data', 'mail']) || user.getIn(['updateUser', 'email']),
          merchantAccount: adyenMerchantAccount,
          amount: { currency: currencyIso, value: 0 },
          paymentMethod,
          reference,
          testResultCode,
          velocityCheckToken,
          billingAddress,
        })
        const paymentAction = _get(payments, ['data', 'action'])

        // handle any payment action that may exist
        if (paymentAction) {
          dropin.handleAction(paymentAction)
        }

        const pspReference = _get(payments, ['data', 'pspReference'])
        const initialResultCode = _get(payments, ['data', 'resultCode'])
        const recurringDetailReference = _get(payments, ['data', 'additionalData', 'recurring.recurringDetailReference'])
        const authorized = initialResultCode === ADYEN_PAYMENT_RESPONSE_CODE_AUTHORIZED
        const pendingOrReceived = initialResultCode === ADYEN_PAYMENT_RESPONSE_CODE_PENDING
          || initialResultCode === ADYEN_PAYMENT_RESPONSE_CODE_RECEIVED
        const authorizedOrPending = authorized || pendingOrReceived
        const giftPaymentPending = isGift && cardPaymentType && pendingOrReceived
        const extraData = {
          billingAddress,
          authorization,
        }

        if (!_get(payments, 'success') || _get(payments, 'error') || !authorizedOrPending || giftPaymentPending) {
          const refusalReason = giftPaymentPending ? giftingPendingRefusalReason : _get(payments, ['data', 'refusalReason'])
          setPaymentTokenFailed({
            auth,
            dropin,
            dispatch,
            refusalReason,
            initialResultCode,
            paymentType,
            authorizationUuid,
            giftPaymentPending,
            velocityCheckToken,
            baseNewRelicData,
          })

          return
        }

        // if this is a credit or debit card, apple pay or google pay,
        // set payment data and update authorization
        if (cardPaymentType || applepayPaymentType || googlepayPaymentType) {
          dispatch(setPaymentData({ ...payments, ...extraData }))
          // close the loop on authorization, setting payment authorization data
          updateAdyenAuthorization({
            uuid: authorizationUuid,
            auth,
            pspReference,
            initialResultCode,
            recurringDetailReference,
          })

          // send authorization data for the velocity check to protect CC abuse
          // but don't send for Pending state
          if (
            authorized
            && velocityCheckActive
            && velocityCheckToken
          ) {
            dispatch({
              type: SET_VELOCITY_AUTHORIZATION,
              payload: { success: true, reason: null },
            })
          }

          // send success data to New Relic
          const authTokenNewRelicSuccessData = {
            paymentType,
            paymentTokenResultCode: initialResultCode,
            paymentTokenSuccess: true,
          }

          addNewRelicAction(
            newRelicAdyenPaymentMonitorName,
            { ...baseNewRelicData, ...authTokenNewRelicSuccessData },
          )
        }
      } else {
        dispatch(setPaymentData(handleGetAdyenPayments({}, true)))
      }
    },
  )
}

/**
 * Watcher for getting payment authorization details from Adyen.
 *
 * @param {Object} param0 The parameters
 * @param {Function} param0.after The witness after function
 */
export function watchGetAdyenPaymentAuthorizationDetails ({ after }) {
  return after(
    [actions.GET_ADYEN_PAYMENT_AUTHORIZATION_DETAILS],
    async ({ dispatch, state, action }) => {
      const {
        auth,
        velocityCheck,
        payment,
        plans,
      } = state
      const { payload } = action
      const { data, dropin, isGift } = payload
      const velocityCheckToken = velocityCheck.getIn(['data', 'velocityCheck', 'token'])
      const authorizationUuid = payment.getIn(['data', 'payments', 'authorization', 'uuid'])
      const details = await getAdyenPaymentDetails({ data, auth })
      const paymentType = _get(details, ['data', 'paymentMethod', 'type'])
      const baseNewRelicData = getBaseNewRelicData(state)
      const pspReference = _get(details, ['data', 'pspReference'])
      const initialResultCode = _get(details, ['data', 'resultCode'])
      const recurringDetailReference = _get(details, ['data', 'additionalData', 'recurring.recurringDetailReference'])
      const authorized = initialResultCode === ADYEN_PAYMENT_RESPONSE_CODE_AUTHORIZED
      const pendingOrReceived = initialResultCode === ADYEN_PAYMENT_RESPONSE_CODE_PENDING
        || initialResultCode === ADYEN_PAYMENT_RESPONSE_CODE_RECEIVED
      const authorizedOrPending = authorized || pendingOrReceived
      const giftPaymentPending = isGift && pendingOrReceived
      const orderId = _get(data, ['details', 'orderID'])
      const detailsCurrencyIso = _get(details, ['data', 'amount', 'currency'])
      const selectedPlan = plans.get('selection') || Map()
      const selectedPlanCurrencyIso = Map.isMap(selectedPlan) ? selectedPlan.get('currencyIso') : undefined
      const currency = selectedPlanCurrencyIso || detailsCurrencyIso || USD
      let extraData = {}

      if (!_get(details, 'success') || _get(details, 'error') || !authorizedOrPending || giftPaymentPending) {
        const refusalReason = giftPaymentPending ? giftingPendingRefusalReason : _get(details, ['data', 'refusalReason'])
        setPaymentTokenFailed({
          auth,
          dropin,
          dispatch,
          refusalReason,
          initialResultCode,
          paymentType,
          authorizationUuid,
          giftPaymentPending,
          velocityCheckToken,
          baseNewRelicData,
        })

        return
      }

      if (paymentType === ADYEN_PAYMENT_TYPE_PAYPAL && orderId) {
        const paypalOrder = await getPaypalOrder({ auth, orderId, currency })
        const paypalAddress = _get(paypalOrder, ['data', 'payment_source', 'paypal', 'address'], {})
        let paypalPostalCode = _get(paypalAddress, 'postal_code', '')
        const paypalCountry = _get(paypalAddress, 'country_code', '')

        if (!paypalPostalCode) {
          if (paypalCountry === US) {
            paypalPostalCode = ADYEN_DEFAULT_US_POSTAL_CODE
          } else if (paypalCountry === CA) {
            paypalPostalCode = ADYEN_DEFAULT_CANADA_POSTAL_CODE
          }
        }

        if (_get(paypalOrder, 'success') && paypalCountry) {
          const billingAddress = formatBillingAddress({
            address1: _get(paypalAddress, 'address_line_1', ''),
            address2: _get(paypalAddress, 'address_line_2', ''),
            city: _get(paypalAddress, 'admin_area_2', ''),
            state: _get(paypalAddress, 'admin_area_1', ''),
            postalCode: paypalPostalCode,
            country: paypalCountry,
          })
          extraData = {
            billingAddress,
            authorization: payment.getIn(['data', 'payments', 'authorization']),
          }
        } else {
          // if the paypal order api call fails, provide some defaults
          // so we don't ruin the checkout process over an address
          const billingAddress = formatBillingAddress({
            address1: ADYEN_FIELD_DEFAULT_VALUE,
            address2: ADYEN_FIELD_DEFAULT_VALUE,
            city: ADYEN_FIELD_DEFAULT_VALUE,
            state: ADYEN_FIELD_DEFAULT_VALUE,
            postalCode: ADYEN_DEFAULT_US_POSTAL_CODE,
            country: US,
          })
          extraData = {
            billingAddress,
            authorization: payment.getIn(['data', 'payments', 'authorization']),
          }
        }
      }

      dispatch(setPaymentData({ ...details, ...extraData }))

      // close the loop on authorization, setting payment authorization data
      updateAdyenAuthorization({
        uuid: authorizationUuid,
        auth,
        pspReference,
        initialResultCode,
        recurringDetailReference,
      })

      // set the dropin to ready since everything happened successfully
      dropin.setStatus('ready')

      // send authorization data for the velocity check to protect CC abuse
      // but don't send for Pending state
      if (
        authorized
        && velocityCheckActive
        && velocityCheckToken
      ) {
        dispatch({
          type: SET_VELOCITY_AUTHORIZATION,
          payload: { success: true, reason: null },
        })
      }

      // send success data to New Relic
      const authTokenNewRelicSuccessData = {
        paymentType,
        paymentTokenResultCode: initialResultCode,
        paymentTokenSuccess: true,
      }

      addNewRelicAction(
        newRelicAdyenPaymentMonitorName,
        { ...baseNewRelicData, ...authTokenNewRelicSuccessData },
      )
    },
  )
}

export function watchSetVelocityAuthorization ({ after }) {
  return after([SET_VELOCITY_AUTHORIZATION], async ({ dispatch, state, action }) => {
    const {
      user = Map(),
      checkout = Map(),
      auth = Map(),
      resolver = Map(),
      velocityCheck = Map(),
      app = Map(),
    } = state
    const { payload } = action
    const { success, reason } = payload
    const userUuid = user.getIn(['data', 'uuid'])
    const velocityCheckToken = velocityCheck.getIn(['data', 'velocityCheck', 'token'])
    let velocityPage = getVelocityCheckActionLocation({ auth, checkout, resolver })
    const fpResult = await getFingerprint({
      fingerprintjsProVersion: app.get('fingerprintjsProVersion'),
    })
    const fingerprintId = _get(fpResult, 'visitorId')
    const requestId = _get(fpResult, 'requestId')

    // adyen-test-page
    if (isTestAdyenPage({ state })) {
      velocityPage = 'checkout'
    }

    await setAuthorization({
      success,
      fingerprintId,
      userInfoUuid: userUuid,
      token: velocityCheckToken,
      failureReason: reason,
      auth,
    })

    if (!success) {
      // if the payment token is not successful, then get a new set of velocity data
      dispatch({
        type: SET_VELOCITY_CHECK_PROCESSING,
        payload: true,
      })
      const velocity = await checkVelocity({
        userInfoUuid: userUuid,
        fingerprintId,
        requestId,
        page: velocityPage,
        auth,
      })
      dispatch({
        type: SET_VELOCITY_CHECK_DATA,
        payload: { data: velocity, processing: false },
      })
    } else {
      dispatch({
        type: SET_VELOCITY_CHECK_PROCESSING,
        payload: false,
      })
    }
  })
    .when(() => {
      return velocityCheckActive
    })
}

export function watchSetAdyenDropInError ({ after }) {
  return after([actions.SET_ADYEN_DROP_IN_ERROR], async ({ dispatch, state, action }) => {
    const {
      user = Map(),
      checkout = Map(),
      payment = Map(),
      auth = Map(),
      resolver = Map(),
      velocityCheck = Map(),
      app = Map(),
    } = state
    const { payload } = action
    const userUuid = user.getIn(['data', 'uuid'])
    const velocityCheckToken = velocityCheck.getIn(['data', 'velocityCheck', 'token'])
    const authorizationUuid = payment.getIn(['data', 'payments', 'authorization', 'uuid'])
    let velocityPage = getVelocityCheckActionLocation({ auth, checkout, resolver })
    const baseNewRelicData = getBaseNewRelicData(state)
    const adyenErrorData = {
      iframeError: payload,
    }
    const iframeErrorRegex = /^cancel/i
    const iframeErrorCancel = iframeErrorRegex.test(String(payload))

    // adyen-test-page
    if (isTestAdyenPage({ state })) {
      velocityPage = 'checkout'
    }

    // remove processing for payments
    dispatch(setPaymentDataProcessing(false))

    // if there is an authorization uuid
    // set discarded since there was an error so the worker can clean up the table properly
    if (authorizationUuid) {
      updateAdyenAuthorization({ uuid: authorizationUuid, auth, discarded: true })
    }

    if (velocityCheckActive && velocityCheckToken) {
      dispatch({
        type: SET_VELOCITY_CHECK_PROCESSING,
        payload: true,
      })

      const fpResult = await getFingerprint({
        fingerprintjsProVersion: app.get('fingerprintjsProVersion'),
      })
      const fingerprintId = _get(fpResult, 'visitorId')
      const requestId = _get(fpResult, 'requestId')

      // set velocity authorization
      await setAuthorization({
        success: false,
        fingerprintId,
        userInfoUuid: userUuid,
        token: velocityCheckToken,
        failureReason: payload,
        auth,
      })
      // fetch another set of velocity data
      const velocity = await checkVelocity({
        userInfoUuid: userUuid,
        fingerprintId,
        requestId,
        page: velocityPage,
        auth,
      })

      dispatch({
        type: SET_VELOCITY_CHECK_DATA,
        payload: { data: velocity, processing: false },
      })
    }

    // send iframe error data to New Relic if is this is not a cancel error
    // this will filter out a lot of noise, since cancels happen a lot
    if (!iframeErrorCancel) {
      addNewRelicAction(
        newRelicAdyenPaymentIframeMonitorName,
        { ...baseNewRelicData, ...adyenErrorData },
      )
    }
  })
}
