import _get from 'lodash/get'
import _map from 'lodash/map'
import { Map, List, Set } from 'immutable'
import { getAssetsList } from 'services/assets/actions'
import { getMultipleGuideDays } from 'services/guide-days/actions'
import {
  setMultipleGuides,
  setGuide,
  setGuideError,
  GET_MULTIPLE_GUIDES,
  GET_GUIDE,
} from './actions'
import {
  getMany as getManyGuide,
  get as getGuide,
} from '.'

/**
 * Get multiple guides and dispatch associated actions
 * @param {Object} options The options
 * @param {import('redux').Dispatch} options.dispatch Redux dispatch function
 * @param {Map} options.auth The auth state
 * @param {Number[]|List|Set} options.guideIds The guide ids
 * @param {String} options.language The request language
 */
async function getGuideMultiple (options = {}) {
  const { dispatch, auth, language } = options
  let { guideIds = [] } = options
  if (List.isList(guideIds) || Set.isSet(guideIds)) {
    guideIds = guideIds.toArray()
  }
  try {
    const guide = await getManyGuide(guideIds, { auth, language })
    // Errors on individual guide are returned inside guide
    // and handled by SET_MULTIPLE_GUIDES
    dispatch(setMultipleGuides(guideIds, language, guide))
  } catch (e) {
    // Do nothing
  }
}

/**
 * Get one video and dispatch associated actions
 * @param {Object} options The options
 * @param {import('redux').Dispatch} options.dispatch Redux dispatch function
 * @param {Map} options.auth The auth state
 * @param {Number} options.guideId The guide id
 * @param {String} options.language The request language
 * @param {Boolean} [options.fetchChildren] When true fetch the assets and guide days
 */
async function getGuideSingle (options = {}) {
  const {
    dispatch, auth, guideId, language, fetchChildren,
  } = options
  try {
    const guide = await getGuide(guideId, { auth, language })
    dispatch(setGuide(guideId, language, guide))
    if (fetchChildren) {
      dispatch(getAssetsList({
        contentId: guideId, contentType: 'guide', language, primaryLanguage: language, contentReferenceType: 'guide-material',
      }))
      const contentIds = _map(_get(guide, 'guideDays', []), 'contentId')
      dispatch(getMultipleGuideDays(contentIds, language, fetchChildren))
    }
  } catch (e) {
    dispatch(setGuideError(guideId, language, e))
  }
}

/**
 * Redux middleware
 * @param {import('redux').Store} store A redux store
 * @returns {import('redux').Middleware} Redux middleware
 */
export default function middleware (store) {
  return (next) => (action) => {
    const { dispatch } = store
    const { auth = Map() } = store.getState()
    switch (action.type) {
      case GET_MULTIPLE_GUIDES: {
        const { guideIds, language } = action.payload
        getGuideMultiple({
          dispatch, auth, guideIds, language,
        })
        break
      }
      case GET_GUIDE: {
        const { guideId, language, fetchChildren } = action.payload
        getGuideSingle({
          dispatch, auth, guideId, language, fetchChildren,
        })
        break
      }
      default:
        break
    }
    next(action)
  }
}
