import _get from 'lodash/get'
import _reduce from 'lodash/reduce'
import { getExact } from 'services/tiles'
import { map as mapPromise } from 'bluebird'

export async function getMany (guideIds, options = {}) {
  const {
    auth,
    language,
  } = options

  // Fetch all data concurrently
  const responses = await mapPromise(guideIds, async (guideId) => {
    try {
      const data = await get(guideId, { auth, language })
      return { guideId, data }
    } catch (error) {
      return { guideId, error }
    }
  })

  // Return { error } or { data } keyed by guideId
  const guide = _reduce(responses, (acc, response) => {
    if (response.error) {
      acc[response.guideId] = { error: response.error }
    } else if (response.data) {
      acc[response.guideId] = { data: response.data }
    }
    return acc
  }, {})

  return guide
}

export async function get (guideId, options = {}) {
  const {
    language,
    auth,
  } = options
  const results = await getExact({
    id: guideId, language, auth,
  })
  const tile = _get(results, 'titles[0]', {})
  if (tile.nid < 1) {
    throw new Error(`Bad guide ${guideId}, response nid: ${tile.nid}`)
  }
  return tile
}
