import _get from 'lodash/get'
import { Record, Map, fromJS } from 'immutable'
import { clearSessionStorage } from 'services/local-preferences'
import * as actions from './actions'

const GiftDetailsFactory = Record({
  recipientFirstName: '',
  recipientLastName: '',
  processing: undefined,
  recipientEmail: '',
  deliveryDate: null,
  deliveredAt: null,
  redeemedAt: null,
  statusCode: null, // 200
  expiresAt: null,
  gifterEmail: '',
  gifterName: '',
  planName: '',
  language: '',
  planSku: '',
  planCurrency: '',
  message: '',
  planId: '',
  token: '',
})

const GiftOrderDataFactory = Record({
  processing: undefined,
  success: undefined,
  newUsername: '',
  userIsNew: undefined,
  billing: Record({
    success: undefined,
    orderNumber: '',
    accountNumber: '',
    status: '',
    subscriptionNumbers: [],
    invoiceNumbers: [],
    paymentNumber: '',
    paidAmount: null,
    accountId: '',
    paymentId: '',
    postalCode: '',
  }),
  gift: Record({
    gifterName: '',
    gifterUuid: '',
    subscriptionStartDate: null,
    subscriptionEndDate: null,
    paymentProvider: '',
    paymentInvoiceNumber: '',
    paymentOrderNumber: '',
    recipientUuid: '',
    recipientFirstName: '',
    recipientLastName: '',
    recipientEmail: '',
    deliveryDate: null,
    deliveredAt: null,
    redeemedAt: null,
    expiresAt: null,
    createdAt: null,
    updatedAt: null,
    language: '',
    planName: '',
    tracking: Record({
      chan: '',
      cid: '',
      utm: '',
      linkshare: Record({
        siteID: '',
        timestamp: null,
      }),
    }),
    planId: '',
    token: '',
  }),
})

const GiftRedeemDataFactory = Record({
  newUsername: '',
  success: undefined,
  userIsNew: undefined,
  processing: false,
})

const GiftOrderErrorFactory = Record({
  success: undefined,
  errorCode: '',
  error: null,
})

const GiftRedeemErrorFactory = Record({
  errorDisplayCode: undefined,
  success: undefined,
  errorCode: '',
})

export const initialState = Map()

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case actions.GIFT_GET_DETAILS: {
      const token = _get(action, 'payload.token')
      const detailsRecord = GiftDetailsFactory({ token, processing: true })
      return state.set('details', detailsRecord)
    }
    case actions.GIFT_SET_DETAILS:
    case actions.GIFT_SET_DETAILS_PERSISTENT: {
      const detailsRecord = GiftDetailsFactory(action.payload)
      return state.set('details', detailsRecord)
    }
    case actions.GIFT_CLEAR_DETAILS: {
      // clearSessionStorage('gift')
      const detailsRecord = GiftDetailsFactory()
      return state.set('details', detailsRecord)
    }
    case actions.GIFT_SET_PLAN_SKU:
      return state.setIn(['details', 'planSku'], action.payload)
    case actions.GIFT_SET_ORDER_PROCESSING:
      return state.setIn(['orderData', 'processing'], action.payload)
    case actions.GIFT_SET_ORDER_DATA: {
      const orderDataRecord = GiftOrderDataFactory(fromJS(action.payload))
      return state.set('orderData', orderDataRecord)
    }
    case actions.GIFT_SET_ORDER_ERROR_DATA: {
      const orderErrorRecord = GiftOrderErrorFactory(fromJS(action.payload))
      return state.set('orderError', orderErrorRecord)
    }
    case actions.GIFT_SET_REDEEM_DATA: {
      const redeemDataRecord = GiftRedeemDataFactory(fromJS(action.payload))
      return state.set('redeemData', redeemDataRecord)
    }
    case actions.GIFT_SET_REDEEM_ERROR_DATA: {
      const redeemErrorRecord = GiftRedeemErrorFactory(fromJS(action.payload))
      return state.set('redeemError', redeemErrorRecord)
    }
    case actions.GIFT_SET_REDEEM_PROCESSING: {
      return state.setIn(['redeemData', 'processing'], action.payload)
    }
    case actions.GIFT_CLEAR_REDEEM_ERROR_DATA: {
      return state.delete('redeemError')
    }
    case actions.GIFT_CLEAR_ORDER_ERROR: {
      return state.delete('orderError')
    }
    case actions.GIFT_CLEAR_RECIPIENT_EMAIL_INFO: {
      clearSessionStorage('gift.recipientEmail') // @Sergei: lets move anything that doesn't have to do
      clearSessionStorage('gift.deliveryDate') // with the store to the watcher file, pls make a watcher for this
      const newState = state.setIn(['details', 'recipientEmail'], '')
      return newState.setIn(['details', 'deliveryDate'], null)
    }
    case actions.GIFT_UNMOUNT:
      return Map()
    default:
      return state
  }
}
