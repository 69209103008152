import { List, Map, fromJS } from 'immutable'
import _get from 'lodash/get'
import { SET_APP_BOOTSTRAP_PHASE, SET_APP_IS_INITIAL_RENDER } from 'services/app/actions'
import { SET_AUTH_LOGIN_SUCCESS } from 'services/auth/actions'
import { SET_USER_ACCOUNT_DATA_BILLING_SUBSCRIPTIONS_WITH_DETAILS } from 'services/user-account/actions'
import { getAuthIsLoggedIn } from 'services/auth'
import {
  setLocalPreferences,
  LOCAL_PREFERENCE_INBOUND_TRACKING,
  LOCAL_PREFERENCE_CONSENT_MANAGEMENT_ACTIVE_GROUPS,
} from 'services/local-preferences'
import { get as getConfig } from 'config'
import {
  getApiIsLoaded,
  getGroupApiIsLoaded,
  complianceCategoryInActiveGroups,
  getFormattedActiveGroups,
  consentManagementAllowAll,
  COMPLIANCE_MANAGEMENT_LISTENER_NAME,
  COMPLIANCE_MANAGEMENT_CAGETORY_TARGETING,
} from 'services/compliance-management'
import { set as setInboundTrackingPersistent } from 'services/inbound-tracking'
import * as actions from './actions'

const config = getConfig()
const oneTrustEnabled = !!_get(config, ['features', 'oneTrust'])
let groupChange = () => null

function shouldSetComplianceManagementData ({ state }) {
  const { auth } = state
  const isLoggedIn = getAuthIsLoggedIn(auth)

  return (
    !isLoggedIn && oneTrustEnabled && getApiIsLoaded() && getGroupApiIsLoaded()
  )
}

function shouldRemoveComplianceManagementRestrictions ({ state }) {
  const { auth } = state
  const isLoggedIn = getAuthIsLoggedIn(auth)

  return (
    isLoggedIn && oneTrustEnabled && getApiIsLoaded() && getGroupApiIsLoaded()
  )
}

/**
 * Watch anonymous bootstrap phase
 */
export function watchComplianceManagementBootstrapAnon ({ after }) {
  return after(SET_APP_BOOTSTRAP_PHASE, async ({ dispatch, state, prevState }) => {
    const { app, auth, inboundTracking } = state
    const { app: prevApp } = prevState
    const prevBootstrapComplete = prevApp.get('bootstrapComplete')
    const nextBootstrapComplete = app.get('bootstrapComplete')
    const inboundTrackingData = inboundTracking.get('data', Map()) || Map()

    // set the event function so it can be removed once logged in
    groupChange = (event) => {
      const { detail } = event

      dispatch(actions.setComplianceManagementActiveGroups(detail))
    }

    if (nextBootstrapComplete && nextBootstrapComplete !== prevBootstrapComplete) {
      const activeGroupsArray = getFormattedActiveGroups()
      const inTargetingCagetory = complianceCategoryInActiveGroups(
        fromJS(activeGroupsArray),
        COMPLIANCE_MANAGEMENT_CAGETORY_TARGETING,
      )

      dispatch(actions.setComplianceManagementActiveGroups(activeGroupsArray))
      setLocalPreferences(
        -1,
        LOCAL_PREFERENCE_CONSENT_MANAGEMENT_ACTIVE_GROUPS,
        activeGroupsArray,
        auth,
      )

      if (inTargetingCagetory && inboundTrackingData.size) {
        setInboundTrackingPersistent({ uid: -1, data: inboundTrackingData, auth })
      }

      // add the listener to pick up any changes
      global.addEventListener(COMPLIANCE_MANAGEMENT_LISTENER_NAME, groupChange)
    }
  })
    .when(shouldSetComplianceManagementData)
}

/**
 * Watch authenticated bootstrap phase
 */
export function watchComplianceManagementBootstrapAuth ({ after }) {
  return after([
    SET_APP_BOOTSTRAP_PHASE,
    SET_APP_IS_INITIAL_RENDER,
    SET_USER_ACCOUNT_DATA_BILLING_SUBSCRIPTIONS_WITH_DETAILS,
  ], async ({ dispatch, state, prevState }) => {
    const {
      app,
      auth,
      inboundTracking,
      userAccount,
    } = state
    const { app: prevApp, userAccount: prevUserAccount } = prevState
    const prevBootstrapComplete = prevApp.get('bootstrapComplete')
    const nextBootstrapComplete = app.get('bootstrapComplete')
    const prevIsInitialRender = prevApp.get('isInitialRender')
    const nextIsInitialRender = app.get('isInitialRender')
    const inboundTrackingData = inboundTracking.get('data', Map()) || Map()
    const prevBillingSubscriptionsProcessing = prevUserAccount.get('billingSubscriptionsProcessing')
    const nextBillingSubscriptionsProcessing = userAccount.get('billingSubscriptionsProcessing')

    // if logged in, allow all categories
    consentManagementAllowAll()

    if (
      (nextBootstrapComplete && nextBootstrapComplete !== prevBootstrapComplete)
        || (nextIsInitialRender && prevIsInitialRender !== nextIsInitialRender)
        || (nextBillingSubscriptionsProcessing !== prevBillingSubscriptionsProcessing)
    ) {
      const activeGroupsArray = getFormattedActiveGroups()
      const inTargetingCagetory = complianceCategoryInActiveGroups(
        fromJS(activeGroupsArray),
        COMPLIANCE_MANAGEMENT_CAGETORY_TARGETING,
      )

      dispatch(actions.setComplianceManagementActiveGroups(activeGroupsArray))
      setLocalPreferences(
        auth.get('uid'),
        LOCAL_PREFERENCE_CONSENT_MANAGEMENT_ACTIVE_GROUPS,
        activeGroupsArray,
        auth,
      )

      if (inTargetingCagetory && inboundTrackingData.size) {
        setInboundTrackingPersistent({ uid: auth.get('uid'), data: inboundTrackingData, auth })
      }
    }
  })
    .when(shouldRemoveComplianceManagementRestrictions)
}

export function watchComplianceManagementActiveGroups ({ after }) {
  return after(
    actions.SET_COMPLIANCE_MANAGEMENT_ACTIVE_GROUPS,
    async ({ state, prevState, dispatch }) => {
      const { complianceManagement, auth, inboundTracking = Map() } = state
      const { complianceManagement: prevComplianceManagement } = prevState
      const prevActiveGroups = prevComplianceManagement.get('groups', List()) || List()
      const nextActiveGroups = complianceManagement.get('groups', List()) || List()
      const groupsAreEqual = nextActiveGroups.equals(prevActiveGroups)

      // if the user has changed the values, reload the page when anonymous
      if (
        !!prevActiveGroups.size
          && !!nextActiveGroups.size
          && !groupsAreEqual
      ) {
        const inTargetingCagetory = complianceCategoryInActiveGroups(
          nextActiveGroups,
          COMPLIANCE_MANAGEMENT_CAGETORY_TARGETING,
        )

        // if there is data in inboundTracking,
        // set it to local storage if the user changes their prefs
        if (inTargetingCagetory) {
          setLocalPreferences(
            -1,
            LOCAL_PREFERENCE_INBOUND_TRACKING,
            inboundTracking.get('data', Map()).toJS(),
            auth,
          )
        }

        // save the latest choices to local storage
        setLocalPreferences(
          -1,
          LOCAL_PREFERENCE_CONSENT_MANAGEMENT_ACTIVE_GROUPS,
          nextActiveGroups.toJS(),
          auth,
        )

        // dispatch an action so other services can listen for it and re-initialize
        dispatch(actions.setComplianceManagementReinitialize())
      }
    },
  )
    .when(shouldSetComplianceManagementData)
}

export function watchComplianceManagementLoginSuccess ({ after }) {
  return after(
    SET_AUTH_LOGIN_SUCCESS,
    async ({ dispatch, state }) => {
      // const { document } = global
      const { inboundTracking, auth } = state
      const inboundTrackingData = inboundTracking.get('data', Map()) || Map()
      const uid = auth.get('uid')

      // if logged in, allow all categories
      consentManagementAllowAll()

      // remove the event listener since the user is logged in
      global.removeEventListener(COMPLIANCE_MANAGEMENT_LISTENER_NAME, groupChange)

      // dispatch an action so other services can listen for it and re-initialize
      dispatch(actions.setComplianceManagementReinitialize())

      // save inboundTracking to ensure it will persist if user logs in off a url
      // that contains UTM data
      setInboundTrackingPersistent({ uid, data: inboundTrackingData, auth })
    },
  )
    .when(shouldRemoveComplianceManagementRestrictions)
}
