import {
  Map,
  fromJS,
} from 'immutable'
import _get from 'lodash/get'
import {
  GET_MULTIPLE_PM_USER_NODE_INFO,
  GET_PM_USER_NODE_INFO,
  SET_MULTIPLE_PM_USER_NODE_INFO,
  SET_PM_USER_NODE_INFO,
  SET_MULTIPLE_PM_USER_NODE_INFO_ERROR,
  SET_PM_USER_NODE_INFO_ERROR,
  SET_FEATURED_EPISODE_USER_NODE_INFO,
  GET_FEATURED_EPISODE_USER_NODE_INFO,
  GET_USER_NODE_INFO_VIDEO_MEDIA_DATA,
  SET_USER_NODE_INFO_VIDEO_MEDIA_DATA,
} from './actions'

export const initialState = Map()

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case GET_MULTIPLE_PM_USER_NODE_INFO: {
      const nodeIds = action.payload
      return state.withMutations((mutableState) => {
        nodeIds.forEach((nodeId) => {
          const key = Number(nodeId)
          mutableState.setIn([key, 'processing'], true)
        })
      })
    }

    case GET_PM_USER_NODE_INFO: {
      const nodeId = action.payload
      const key = Number(nodeId)
      return state.setIn([key, 'processing'], true)
    }

    case SET_MULTIPLE_PM_USER_NODE_INFO: {
      const { nodeIds, data } = action.payload
      return state.withMutations((mutableState) => {
        nodeIds.forEach((nodeId) => {
          const key = Number(nodeId)
          mutableState
            .setIn([key, 'processing'], false)
            .setIn([key, 'data'], fromJS(data[key] || {}))
            .deleteIn([key, 'error'])
        })
      })
    }

    case SET_PM_USER_NODE_INFO: {
      const { nodeId, data } = action.payload
      const key = Number(nodeId)
      return state.withMutations((mutableState) => {
        mutableState
          .setIn([key, 'processing'], false)
          .setIn([key, 'data'], fromJS(data))
          .deleteIn([key, 'error'])
      })
    }

    case SET_MULTIPLE_PM_USER_NODE_INFO_ERROR: {
      const { nodeIds, error } = action.payload
      return state.withMutations((mutableState) => {
        nodeIds.forEach((nodeId) => {
          const key = Number(nodeId)
          mutableState
            .setIn([key, 'processing'], false)
            .deleteIn([key, 'data'])
            .setIn([key, 'error'], error)
        })
      })
    }

    case SET_PM_USER_NODE_INFO_ERROR: {
      const { nodeId, error } = action.payload
      const key = Number(nodeId)
      return state.withMutations((mutableState) => {
        mutableState
          .setIn([key, 'processing'], false)
          .deleteIn([key, 'data'])
          .setIn([key, 'error'], error)
      })
    }

    // when we are getting series nodes, we also need to grab the featured episode info now
    // so that we can show the continue watching episode (all series tiles should going forward)
    // IMO this should be refactored to happen in brooklyn and come back with the batchget req
    // but apps is already using this secondary endpoint so we are going with this implementation
    // for now
    case GET_FEATURED_EPISODE_USER_NODE_INFO: {
      const nodeIds = action.payload
      return state.withMutations((mutableState) => {
        nodeIds.forEach((nodeId) => {
          const key = Number(nodeId)
          mutableState.setIn([key, 'featuredEpisode', 'processing'], true)
        })
      })
    }

    case SET_FEATURED_EPISODE_USER_NODE_INFO: {
      const { nodeIds, featuredEpisodes } = action.payload
      return state.withMutations((mutableState) => {
        nodeIds.forEach((nodeId) => {
          const featuredEpisode = featuredEpisodes[nodeId]
          const {
            error,
            data,
            media,
            userNode,
          } = featuredEpisode
          const key = Number(nodeId)
          mutableState
            .setIn([key, 'featuredEpisode', 'processing'], false)
            .setIn([key, 'featuredEpisode', 'data'], fromJS(data))
            .setIn([key, 'featuredEpisode', 'media'], fromJS(media))
            .setIn([key, 'featuredEpisode', 'error'], fromJS(error))
            .setIn([key, 'featuredEpisode', 'userNode'], fromJS(userNode))
        })
      })
    }

    // getting media info for videos/episodes
    case GET_USER_NODE_INFO_VIDEO_MEDIA_DATA: {
      const nodeMediaData = action.payload || []
      return state.withMutations((mutableState) => {
        nodeMediaData.forEach((item) => {
          const key = Number(_get(item, 'id'))
          mutableState.setIn([key, 'mediaProcessing'], true)
        })
      })
    }

    case SET_USER_NODE_INFO_VIDEO_MEDIA_DATA: {
      const nodeMediaData = action.payload || []
      return state.withMutations((mutableState) => {
        nodeMediaData.forEach((item) => {
          const key = Number(_get(item, 'id'))
          mutableState
            .setIn([key, 'mediaProcessing'], false)
            .setIn([key, 'data', 'media'], fromJS(_get(item, 'media')))
        })
      })
    }

    default:
      return state
  }
}

/*
Store
pmUserNodeInfo: Map(
  4891: {
    processing: boolean,
    data: Map(),
    error: Map(),
  },
)
*/
