import _size from 'lodash/size'
import _compact from 'lodash/compact'
import {
  setMultiplePmUserNodeInfo,
  setMultiplePmUserNodeInfoError,
  setPmUserNodeInfo,
  setPmUserNodeInfoError,
  GET_MULTIPLE_PM_USER_NODE_INFO,
  GET_PM_USER_NODE_INFO,
  GET_FEATURED_EPISODE_USER_NODE_INFO,
  GET_USER_NODE_INFO_VIDEO_MEDIA_DATA,
  setFeaturedEpisodeUserNodeInfo,
  setUserNodeInfoVideoMediaData,
} from './actions'
import {
  getFeaturedEpisodeData,
  getVideoMediaData,
  getMany,
  getOne,
} from '.'

async function getMultiple (dispatch, actionPayload, options = {}) {
  const {
    auth,
  } = options
  const nodeIds = actionPayload
  const filteredNodeIds = _compact(nodeIds)

  if (_size(filteredNodeIds) === 0) {
    return
  }
  try {
    // Use batch getMany()
    const data = await getMany(filteredNodeIds, { auth })
    dispatch(setMultiplePmUserNodeInfo(filteredNodeIds, data))
  } catch (e) {
    dispatch(setMultiplePmUserNodeInfoError(filteredNodeIds, e))
  }
}

async function getSingle (dispatch, actionPayload, options = {}) {
  const {
    auth,
  } = options
  const nodeId = actionPayload
  try {
    // Use single getOne()
    const pmUserNodeInfo = await getOne(nodeId, { auth })
    dispatch(setPmUserNodeInfo(nodeId, pmUserNodeInfo))
  } catch (e) {
    dispatch(setPmUserNodeInfoError(nodeId, e))
  }
}

async function getFeaturedEpisodes (dispatch, actionPayload, options = {}) {
  const {
    auth,
    user,
  } = options
  const nodeIds = actionPayload
  const filteredNodeIds = _compact(nodeIds)

  if (_size(filteredNodeIds) === 0) {
    return
  }

  try {
    const featuredEpisodes = await getFeaturedEpisodeData(filteredNodeIds, { auth, user })
    dispatch(setFeaturedEpisodeUserNodeInfo(filteredNodeIds, featuredEpisodes))
  } catch (e) {
    // silent fail
  }
}

async function getUserInfoMediaData (dispatch, actionPayload, options = {}) {
  try {
    const data = await getVideoMediaData(actionPayload, options)
    dispatch(setUserNodeInfoVideoMediaData(data))
  } catch (e) {
    // silent fail
  }
}

/**
 * Redux middleware
 * @param {import('redux').Store} store A redux store
 * @returns {import('redux').Middleware} Redux middleware
 */
export default function middleware (store) {
  return (next) => (action) => {
    const { dispatch } = store
    const state = store.getState()
    const { auth, user } = state
    switch (action.type) {
      case GET_MULTIPLE_PM_USER_NODE_INFO:
        getMultiple(dispatch, action.payload, { auth })
        break

      case GET_PM_USER_NODE_INFO:
        getSingle(dispatch, action.payload, { auth })
        break

      case GET_FEATURED_EPISODE_USER_NODE_INFO:
        getFeaturedEpisodes(dispatch, action.payload, { auth, user })
        break

      case GET_USER_NODE_INFO_VIDEO_MEDIA_DATA:
        getUserInfoMediaData(dispatch, action.payload, { auth, user })
        break

      default:
        break
    }
    next(action)
  }
}
