import { fromJS } from 'immutable'
import _get from 'lodash/get'
import { getAuthIsLoggedIn } from 'services/auth'
import { SET_APP_BOOTSTRAP_PHASE } from 'services/app/actions'
import { BOOTSTRAP_PHASE_COMPLETE } from 'services/app'
import { SET_AUTH_DATA } from 'services/auth/actions'
import { getOnboardingStatus } from './actions'

/**
 * Redux middleware
 * @param {import('redux').Store} store A redux store
 * @returns {import('redux').Middleware} Redux middleware
 */
export default function middleware (store) {
  return (next) => async (action) => {
    switch (action.type) {
      case SET_APP_BOOTSTRAP_PHASE: {
        const { payload = {} } = action
        const { phase } = payload
        const { auth } = store.getState()

        if (getAuthIsLoggedIn(auth) && phase === BOOTSTRAP_PHASE_COMPLETE) {
          store.dispatch(getOnboardingStatus({ auth }))
        }
        break
      }
      case SET_AUTH_DATA: {
        store.dispatch(getOnboardingStatus({ auth: fromJS(_get(action, 'payload.data')) }))
        break
      }
      default:
        break
    }
    next(action)
  }
}
